<template>
  <section class="simple-snotify">
    <button class="btn btn-outline-success mx-auto" v-on:click="showSnotify">Click here!</button>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script scoped lang="js">
import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}
Vue.use(Snotify, options)
export default {
  name: 'simple-snotify',
  // props:{
  //   msg: String
  // },
  methods: {
    async showSnotify() {
      let obj = await this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
}
</script>

<style scoped lang="scss">
.simple-snotify {
  display: flex;
  justify-content: center;
}
</style>
